@import "../../sass/colors.scss";

.mpharma-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border-radius: 6px;
  font-family: Sofia Pro;
  &.mpharma-primary {
    border: 1px solid $mpharma-badge-primary-border;
    color: $mpharma-badge-primary-color;
  }
  &.mpharma-secondary {
    border: 1px solid $mpharma-badge-secondary-border;
    color: $mpharma-badge-secondary-color;
  }
  &.mpharma-tertiary {
    border: 1px solid $mpharma-badge-tertiary-border;
    color: $mpharma-badge-primary-color;
  }
  &.clickable {
    cursor: pointer;
  }
}
