.mpharma-product-name-preview {
  width: 530px;
  border-radius: 16px;
  border: 1px solid #6B7B8A;
  margin: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14),
    0px 1px 8px rgba(0, 0, 0, 0.12);
  .wrapper {
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .mpharma-product-name-preview-body {
      display: flex;
      align-items: center;
      column-gap: 2px;
    }
    .mpharma-product-name-preview-action {
      display: flex;
      align-items: center;
      column-gap: 20px;
      .spellcheck {
        display: flex;
        column-gap: 5px;
      }
    }
  }
}
