.mpharma-form-group {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.mpharma-form-control {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
