@import "../../sass/shadows.scss";
.product-type-widget {
  width: 256px;
  height: 144px;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  position: relative;
  &:hover {
    border: 1px solid #6b7b8a;
    background: #e8ebec;
  }
  .image-area {
    width: 113px;
    height: 64px;
    background: #e2e2e6;
    border-radius: 8px;
    position: absolute;
    top: 20px;
    left: 24px;
  }
  .footer-area {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    top: 110px;
    margin-left: 24px;
    .label {
      font-size: 16px;
      color: #000;
    }
  }
  &.selected {
    border: 1px solid #35cae2;
    background: rgba(53, 202, 226, 0.08);
    .image-area {
      background: #587cc8;
    }
  }
}

// .product-type-widget {
//   width: 284px;
//   height: 284px;
//   background: #ffffff;
//   box-shadow: $product-type-widget-shadow;
//   border-radius: 16px;
//   position: relative;

//   .image-area {
//     position: absolute;
//     width: 160px;
//     height: 160px;
//     left: 16px;
//     top: 19px;
//     background: #e2e2e6;
//     border-radius: 16px;
//   }
//   .footer-area {
//     display: flex;
//     align-items: center;
//     position: absolute;
//     justify-content: space-between;
//     width: 100%;
//     height: 24px;
//     top: 244px;
//     padding:0 20px;
//   }
// }
