@import "../../sass/colors.scss";

.mpharma-typography {
  color: #000000;
  font-family: "Sofia Pro";
  margin: 0;
  &.mpharma-secondary {
    color: #6b7b8a;
  }
  &.mpharma-main {
    color: #fdc300;
  }
  &.h1,
  &.h2,
  &.h3 {
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }
  &.h1 {
    font-size: 32px;
  }
  &.h2 {
    font-size: 26px;
  }
  &.h3 {
    font-size: 22px;
  }
  &.h4 {
    font-size: 20px;
  }
  &.h5 {
    font-size: 16px;
  }
  &.h6 {
    font-size: 14px;
  }
  &.label {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #384853;
  }
  &.error {
    color: $mpharma-input-error-color;
    font-size: 13px;
    font-weight: 400;
  }
  &.p {
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
  }
  &.body-4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }
  &.body-3 {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  }
  &.body-2 {
    font-size: 14px;
    font-weight: normal;
  }
  &.body-1 {
    font-size: 12px;
    font-weight: normal;
  }
}
