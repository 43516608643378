.mpharma-products-section {
  width: 100%;
  .main-content {
    padding-left: 64px;
    margin-top: 20px;
    border-bottom: 1px solid #e9ecee;
    .mpharma-product-table-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      button {
        margin-right: 30px;
      }
    }
  }
}

.MuiGrid-container{
  margin-top: 20px;
}