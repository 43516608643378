.mpharma-search-input {
  display: flex;
  border: 1px solid #e3e6ea;
  align-items: center;
  border-radius: 6px;
  padding: 0 8px;
  .input {
    border: none;
    padding: 10px;
    flex: 1;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: "Sofia Pro";
      font-size: 14px;
      font-weight: 400;
    }
  }
  .search-wrapper {
    cursor: pointer;
    padding: 3px;
    background-color: #fdc300;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    .search-icon {
      color: #e3e6ea;
    }
  }
  .prefix-search-icon {
    color: #6b7b8a;
  }
  &:hover {
    border: 1px solid #fdc300;
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      border: 1px solid #e3e6ea;
    }
  }
}
