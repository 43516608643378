// .sidebar-menu-wrapper {
//   padding: 1em;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   justify-content: center;
//   .product-creation-form-side-menu {
//     display: flex;
//     flex-direction: column;
//     height: 95vh;
//     min-width: 600px;
//     .header {
//       width: 100%;
//       padding: 20px 0;
//       .header-left {
//         display: flex;
//         align-items: center;
//         column-gap: 1em;
//       }
//     }
//     .Body {
//       margin-top: 1em;
//       padding-bottom: 60px;
//     }
//     .footer {
//       margin-top: auto;
//       padding: 1em;
//       .mpharma-hyperlink {
//         text-align: center;
//       }
//     }
//   }
// }
.product-type-form {
  margin-bottom: 20px;
}

.sidebar-menu-wrapper {
  padding: 20px 0;
}
.product-type-form .mpharma-icon-button {
  float: left;
  padding: 3px;
  margin-right: 20px;
}
.form-title {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.product-creation-form-side-menu .footer {
  margin-top: 20px;
  position: fixed;
  bottom: 20px;
  width: 600px;
  background-color: #fff;
  text-align: center;
}

.product-creation-form-side-menu .footer .mpharma-hyperlink {
  margin-top: 20px;
  font-size: 14px;
}
.Body .mpharma-new-drug {
  position: fixed;
  height: 55%;
  width: 520px;
  padding: 24px;
  overflow: auto;
}
.Body .mpharma-new-drug-no-name {
  position: fixed;
  height: 85%;
  width: 520px;
  padding: 24px;
  overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.Body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.Body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.mpharma-product-name-preview-header .mpharma-typography.p {
  text-align: left;
  font-size: 12px;
}
