.mpharma-nav-space {
  width: 100%;
  height: 70px;
  .title {
    font-weight: 800;
    margin-top: 28px;
    font-size: 24px;
    margin-left: 78px;
  }
}
