.mPharma-product-success-wrapper{
    margin: 24px;
    margin-top: 36px;
}

.mPharma-product-success-wrapper-text {
    margin: 24px 0px;
}

.success-text{
    color: var(--grey-grey-006, #6B7B8A);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.product-created{
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px; 
}

.mPharma-product-success-wrapper-btn{
    width: 160px;
    height: 16px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 800;
}

.mPharma-product-success-wrapper-cancelBtn{
    height: 16px;
    border-radius: 4px;
    width: 100px;
    font-size: 12px;
    font-weight: 800;
}