.mpharma-product-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  .mpharma-product-types-container {
    width: 520px;
    display: grid;
    grid-template-columns: 0fr 1fr;
    column-gap: 8px;
    row-gap: 8px;
    flex-grow: 1;
    max-height: 75vh;
  }
  .mpharma-product-type-footer {
    margin-top: auto;
  }
}
