.mpharma-formulary-view {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  .formulary-item {
    border: 1px solid #e8ebec;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    .product-details-wrapper{
      display: flex;
      justify-content: space-between;
    }
    .product-image {
      width: 40px;
      height: 40px;
    }
    .name-section {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      margin-left: 16px;
    }
    .badges-actions {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
  .pagination-section {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
