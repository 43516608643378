.mpharma-ordinal-title {
  color: #b0b0b0;
}
.mpharma-ingredients-strength {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin-top: 20px;
  .mpharma-ingredient-form-ctrl {
    &.dosage {
      flex-grow: 2;
    }
    &.unit {
      flex-grow: 1;
    }
  }
  .mpharma-ingredient-form-label {
    margin-bottom: 10px;
  }
}
