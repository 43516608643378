@import "./res/styles/index.scss";
body {
  margin: 0;
  font-weight: normal;
  font-family: "Sofia Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-weight: normal;
  font-family: "Sofia Pro";
}
#vdl {
  background-color: #ffffff;
  overflow-x: hidden;
}
