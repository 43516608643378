@import "../../sass/colors.scss";
.mpharma-button {
  outline: none;
  border: none;
  border-radius: 12px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1em;

  cursor: pointer;
  background-color: $mpharma-default-button-background-color;
  color: $mpharma-default-button-color;
  &.mpharma-primary {
    background-color: $mpharma-primary-button-background-color;
    color: $mpharma-primary-button-color;
    &:hover {
      background-color: $mpharma-primary-button-hover-background-color;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background: $mpharma-primary-button-background-color;
      }
    }
  }
  &.mpharma-secondary {
    background-color: $mpharma-primary-button-secondary-color;
    color: $mpharma-secondary-button-color;
    border: 1px solid $mpharma-secondary-button-border-color;
    font-weight: 800;
    &:hover {
      border-color: $mpharma-secondary-button-hover-border-color;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background: $mpharma-primary-button-background-color;
      }
    }
  }
  &.mpharma-text {
    background-color: $mpharma-text-button-background-color;
    color: $mpharma-text-button-color;
    padding: 1em 0em;
  }
}
