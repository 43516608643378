.mpharma-new-drug,
.mpharma-new-drug-no-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  .mpharma-brand-information {
    .mpharma-branded-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.mpharma-typography.p.input-label {
  // margin-bottom: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #384853;
}

.mpharma-typography.h5.input-label {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mpharma-typography.h3.input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.MuiOutlinedInput-root {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 61px;
  height: 40px;
  background: #ffffff;
  border-color: #e3e6ea;
  border-radius: 6px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-size: 13px;
}
.Mui-focused.MuiOutlinedInput-root {
  border-color: red !important;
}
// .MuiOutlinedInput-root {
//   "&:hover": {
//     border-color: red !important;
//   }
// }

.MuiSelect-icon {
  color: #e3e6ea;
}
.Mui-disabled .MuiOutlinedInput-root {
  border-color: #e3e6ea;
  border-radius: 6px;
}

.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background: #f9fafb;
  border: 1px solid #e3e6ea !important;
  border-radius: 6px;
}

.mpharma-ingredient-form-button{
  margin-top: 10px;
  border-radius: 4px;
  width: 200px;
}
