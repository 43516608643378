.Mui-selected {
  background-color: rgba(253, 195, 0, 0.1);
  border-radius: 10px;
}

.CustomTab-root > div > span {
  display: none;
}

.CustomTab-root {
  margin-left: 14px;
}

.mpharma-table-filter-bar {
  margin-left: 14px;
  margin-bottom: 24px;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  .search-input {
    flex: 1;
    height: 40px;
  }
  .new-product-button {
    border-radius: 6px;
    font-weight: 800;
    font-size: 13px;
    font-family: "Sofia Pro";
    height: 40px;
  }
}
