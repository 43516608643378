.mpharma-active-ingredient-selector {
  display: flex;
  column-gap: 1em;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
}

.mpharma-ingredient-form-label {
  margin-bottom: 10px;
}