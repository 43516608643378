$mpharma-error: #eb5757;
$mpharma-secondary: #ff5100;

//- Button

// -- background
$mpharma-default-button-background-color: #e2e2e5;
$mpharma-primary-button-background-color: #fdc300;
$mpharma-primary-button-secondary-color: #ffffff;
$mpharma-primary-button-hover-background-color: #e9b301;
$mpharma-text-button-background-color: #ffffff;

// -- Color
$mpharma-default-button-color: rgba(0, 0, 0, 0.3);
$mpharma-primary-button-color: #ffffff;
$mpharma-secondary-button-color: #384853;
$mpharma-text-button-color: #000000;
$mpharma-border-color: #e9ecee;
$mpharma-nav-text-button-color: #384853;
$mpharma-nav-active-text-button-color: $mpharma-secondary;

//-- Border
$mpharma-secondary-button-border-color: #384853;
$mpharma-secondary-button-hover-border-color: #172025;

//- INPUT
$mpharma-input-border-color: #e3e6ea;
$mpharma-input-focus-border-color: #ced1d4;
$mpharma-input-text-color: #6b7b8a;
$mpharma-input-error-color: $mpharma-error;

//Search input

// Badge
$mpharma-badge-primary-border: #5aa587;
$mpharma-badge-primary-color: #5aa587;
$mpharma-badge-secondary-border: #ff5100;
$mpharma-badge-secondary-color: #ff5100;
$mpharma-badge-tertiary-border: #587cc8;
$mpharma-badge-primary-color: #6b7b8a;

//
